.dia,
.dia-1,
.dia-2,
.dia-3,
.dia-5 {
  animation-duration: 7s;
}

.mt-15 {
  margin-top: 15px !important;
}

.wt-96 {
  width: 96% !important;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  visibility: visible !important;
  overflow-y: scroll !important;
}

.index {
  background: #000 !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.activeNav {
  transform: rotate(135deg) !important;
  border-radius: 0px !important;
}

.wow {
  visibility: hidden;
}

.inactiveNav {
  border-radius: 50%;
  transform: rotate(0deg);
}

.op-1 {
  opacity: 1;
}

.op-25 {
  opacity: 0.25 !important;
}

.op-75 {
  opacity: 0.75 !important;
}

.op-0 {
  opacity: 0 !important;
}

.bg-default {
  background: rgb(158, 13, 13);
}

.brd-diamond-default {
  border: 2px solid rgb(158, 13, 13) !important;
}

.links li:hover,
.logo,
.nav-out li,
.over-toggle,
.toggle-nav {
  cursor: pointer;
}

#diamond,
.diamond {
  -webkit-transform: rotate(-45deg);
}

.resume-link,
.resume-link:hover,
a {
  text-decoration: none;
}

.link-head {
  display: block;
  color: rgb(160, 17, 17);
}

.clr-white {
  color: #fff;
}

.nav-home {
  color: rgb(37, 37, 37);
}

.nav-projects {
  color: rgb(154, 154, 154);
}

.nav-exp {
  color: rgb(154, 154, 154);
}

.nav-skills {
  color: rgb(154, 154, 154);
}

.nav-more {
  color: rgb(154, 154, 154);
}

.nav-contact {
  color: rgb(154, 154, 154);
}

.my-pic,
.side-img-lg,
body {
  overflow: hidden;
}

@font-face {
  font-family: geo;
  src: url(../fonts/Geometos.ttf);
}

@font-face {
  font-family: mons-reg;
  src: url(../fonts/Montserrat-Regular.otf);
}

@font-face {
  font-family: mons-lig;
  src: url(../fonts/Montserrat-Light.otf);
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  width: 0;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: red;
}

.mob-body,
body {
  background: #fff;
}

.example,
.sign,
p {
  font-size: 1em;
  color: #9d9d9d;
  text-align: left;
  padding: 20px;
  display: block;
}

.light-msg {
  color: #9d9d9d;
}

.example {
  color: #4d4d4d;
}

#message-box,
.head {
  text-align: left;
  font-size: 2em;
  margin: 3.2% 3.2% 3.2% 20px;
}

.header,
.load-text,
.nav-line li,
.nav-out {
  text-align: center;
}

#diamond,
.load-text {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.head {
  font-size: 3em;
}

a {
  color: #35709a;
  transition: all 0.2s ease;
}

a:hover {
  color: #9d9d9d;
}

body {
  margin: 0;
  padding: 0;
  visibility: hidden;
  height: 100%;
  font-family: sans-serif;
}

.links h4,
.load-text,
.load-title,
.loader-text {
  font-family: mons-lig;
}

.links h4 {
  color: #a01111;
  opacity: 0.8;
  padding-left: 60px;
  margin-top: -33px;
  font-size: 1em;
}

#numd {
  color: #000;
}

.loader-text {
  display: none;
  width: 100%;
  z-index: 1;
  height: 95%;
  color: #fff;
  font-size: 1.4em;
  padding-top: 80px;
  position: absolute;
  bottom: 40px;
}

.loader,
.loadonmob {
  display: block;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  z-index: 3000000;
  text-align: center;
}

.loadonmob {
  visibility: visible;
}

.load-text {
  position: absolute;
  bottom: 20px;
  font-size: 1em;
  color: gray;
}

.loader .fa-mobile {
  font-size: 1.2em;
}

.loader-logo {
  display: inline-block;
  margin-top: 25vh;
  width: 100px;
  height: 130px;
}

.home,
.mainpage {
  height: 100vh;
  width: 100%;
}

.loadonmob .loader-logo {
  margin-top: 15vh;
}

.loader-logo img {
  height: 250px;
  width: 250px;
  margin-top: -128%;
  margin-left: -72%;
}

.load-title {
  display: block;
  font-size: 1.5em;
  color: grey;
}

.load-dyn {
  font-size: 1.3em;
  font-family: mons-reg;
  color: #787878;
  margin-top: 6%;
}

.loadmobtext,
.side-con {
  font-family: mons-lig;
}

.mainpage {
  position: absolute;
  z-index: 1;
  display: none;
  margin-top: 0;
  transition: opacity 0.5s ease-in-out;
}

.back-image,
.header,
.home {
  display: block;
}

#diamond,
.line {
  display: block;
  transition: all 0.4s ease;
}

.loadmobtext {
  font-size: 1em;
  color: gray;
  padding: 20px;
}

.home {
  background-color: #dc2c2c;
  position: fixed;
  z-index: -1;
}

.side-con {
  border-bottom: 0;
  font-size: 0.85em;
}

.header,
.my-intro {
  font-family: geo;
}

.back-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0.1;
  right: 0;
  background-size: contain;
}

.header {
  top: 0;
  padding-right: 35px;
  padding-left: 35px;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 2000;
  color: #fff;
}

#diamond,
.line2,
.nav-out,
.page-name,
.page-num {
  position: absolute;
}

#diamond {
  width: 25px;
  height: 25px;
  border: 2px solid #af1616;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  top: 23px;
}

.logo,
.toggle-nav {
  top: 15px;
}

.line {
  width: 2px;
  height: 20px;
  margin-left: 1px;
  background-color: #aa1616;
}

.menu-active {
  border-radius: 0px;
  transform: rotate(135deg);
}

.menu-inactive {
  border-radius: 50%;
  transform: rotate(0deg);
}

.dot-menu,
.resume-link {
  transition: all 0.2s ease;
}

.page-num {
  margin-left: -3px;
  margin-top: 7px;
  font-size: 0.9em;
}

.line2 {
  bottom: 0;
}

.page-name {
  bottom: 20px;
  right: 10px;
  font-size: 0.9em;
}

.nav-out,
.nav-out li .diamond,
.nav-out ul {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.resume-link {
  color: #252525;
}

.resume-link:hover {
  color: #fff;
}

.nav-out li {
  list-style: none;
  margin-top: 25px;
  margin-bottom: 25px;
}

.dot-menu {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
}

.nav-out {
  display: table;
  height: 100%;
  width: 100%;
  z-index: 7;
}

.nav-out ul {
  padding-left: 0 !important;
  display: table-cell;
  vertical-align: middle;
}

.diamond {
  width: 18px;
  height: 18px;
  background-color: #aa1616;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  display: inline-block;
  background: rgb(158, 13, 13);
}

.nav-out li .diamond,
.nav-out li .nav-name {
  position: absolute;
  margin-top: -17px;
  display: block;
  transition: all 0.2s ease-in-out;
  color: rgb(158, 13, 13);
}

.nav-out li .diamond {
  z-index: -1;
  background-color: #aa1616;
  opacity: 0;
}

.nav-out li:hover .diamond,
.nav-out li:hover .nav-name {
  opacity: 1;
}

.nav-out li .nav-name {
  right: 54px;
  z-index: 8;
  color: #aa1616;
  opacity: 0;
}

.dia-1,
.dia-4,
.dia-5 {
  opacity: 0.3;
}

.nav-line,
.toggle-nav {
  position: fixed;
  display: none;
}

.logo {
  position: fixed;
  left: 20px;
  z-index: 1000;
}

.logo img {
  width: 100px;
  margin-top: -30px;
  margin-left: -33px;
  height: 100px;
}

.toggle-nav {
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 15px;
}

.line-l,
.line-s,
.line-s2 {
  height: 1px;
  background-color: #fff;
}

.lineb1 {
  background: rgb(158, 13, 13) !important;
}

.diamond1 {
  border: 2px solid rgb(158, 13, 13) !important;
}

.lineb2 {
  background: rgb(84, 84, 84) !important;
}

.diamond2 {
  border: 2px solid rgb(84, 84, 84) !important;
}

.lineb3 {
  background: rgb(253, 179, 78) !important;
}

.diamond3 {
  border: 2px solid rgb(253, 179, 78) !important;
}

.lineb4 {
  background: rgb(10, 35, 86) !important;
}

.diamond4 {
  border: 2px solid rgb(10, 35, 86) !important;
}

.lineb5 {
  background: rgb(69, 117, 21) !important;
}

.diamond5 {
  border: 2px solid rgb(69, 117, 21) !important;
}

.lineb6 {
  background: rgb(0, 0, 0) !important;
}

.diamond6 {
  border: 2px solid rgb(0, 0, 0) !important;
}

.nav-line {
  right: 19px;
  top: 8px;
  z-index: 12000;
}

.nav-line li {
  display: block;
  list-style: none;
  padding-top: 0;
}

.line-s,
.line-s2 {
  display: block;
  width: 12px;
  transition: all 0.3s ease-in-out;
  margin-top: 14px;
  margin-left: 3px;
}

.line-l,
.line-s2 {
  margin-top: 4px;
}

.line-l {
  display: block;
  width: 18px;
}

.intro-outer {
  position: absolute;
  z-index: 2;
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.intro-inner,
.my-intro,
.my-pic {
  display: inline-block !important;
}

.intro-inner {
  position: fixed;
  padding-top: 50px;
  margin-top: 150px;
}

.my-pic {
  width: 120px;
  height: 135px;
  border-radius: 3px;
  box-shadow: 2px 2px 2px #ac1414;
  margin-left: 70px;
}

.dia,
.dia-2,
.dia-3,
.dia-4,
.dia-5 {
  position: absolute;
  z-index: -1;
  border: 2px solid #aa1616;
}

.red-intro {
  color: #aa1616;
}

.my-pic img {
  width: 150px;
  margin-top: -8px;
  margin-left: -10px;
  padding-bottom: 10px;
}

.my-intro {
  margin-left: 10px;
  font-size: 1.9em;
  color: #fff;
  line-height: 37px;
}

.my-detail {
  color: #fff;
  font-size: 1.6em;
  font-family: mons-lig;
  line-height: 30px;
  margin: 10px 70px;
}

.horiz-line {
  display: block;
  width: 440px;
  margin-left: 70px;
  height: 1px;
  background-color: #fff;
  margin-top: 10px;
}

.cotainer-fluid {
  display: table !important;
}

.dia,
.dia-2,
.dia-3,
.dia-4,
.dia-5,
.nav-cross,
.side-mob-nav li {
  display: block;
}

.dia {
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dia-1 {
  margin-left: 100px;
  width: 70px;
  height: 70px;
  margin-top: -100px;
  animation-name: trans;
}

.dia-2,
.dia-3 {
  margin-left: 50px;
  opacity: 0.3;
}

.dia-2 {
  margin-top: -130px;
  animation-name: trans2;
}

.dia-3 {
  margin-top: -300px;
  animation-name: trans3;
}

.dia-4 {
  margin-left: 350px;
  margin-top: -260px;
  animation-name: trans4;
}

.dia-5 {
  margin-left: 400px;
  margin-top: -300px;
  animation-name: trans5;
}

@keyframes trans {
  from {
    width: 70px;
    height: 70px;
    -webkit-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    transform: rotate(140deg);
  }

  to {
    width: 90px;
    height: 90px;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@keyframes trans2 {
  from {
    width: 100px;
    height: 100px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }

  to {
    width: 60px;
    height: 60px;
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
}

@keyframes trans3 {
  from {
    width: 120px;
    height: 120px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  to {
    width: 60px;
    height: 60px;
    -webkit-transform: rotate(100deg);
    -ms-transform: rotate(100deg);
    transform: rotate(100deg);
  }
}

@keyframes trans4 {
  from {
    width: 110px;
    height: 110px;
    -webkit-transform: rotate(70deg);
    -ms-transform: rotate(70deg);
    transform: rotate(70deg);
  }

  to {
    width: 60px;
    height: 60px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }
}

@keyframes trans5 {
  from {
    width: 70px;
    height: 70px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  to {
    width: 110px;
    height: 110px;
    -webkit-transform: rotate(100deg);
    -ms-transform: rotate(100deg);
    transform: rotate(100deg);
  }
}

.links {
  margin-left: -20px;
  z-index: 1000;
  position: fixed;
  bottom: 15px;
}

.links li {
  list-style: none;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.links li i {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;
}

.side-mob-black,
.side-mob-white {
  position: fixed;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.links li:hover i {
  color: #fff !important;
}

.side-mob-white {
  top: 0;
  width: 200px;
  background-color: #fff;
  z-index: 10000;
  margin-left: -200px;
}

.side-mob-black {
  width: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 9000;
}

.side-img,
.side-img-lg {
  position: absolute;
  display: block;
}

.side-img-lg {
  color: #525252;
  z-index: -1;
  width: 200px;
  height: 100px;
  border-radius: 3px;
}

.side-img-lg img {
  width: 200px;
  margin-top: -60px;
  opacity: 0.2;
}

.side-img img {
  width: 185px;
  height: 123px;
  margin-left: 9px;
  margin-top: -20px;
}

.side-mob-nav {
  margin-left: -40px;
  color: #9a9a9a;
  margin-top: 120px;
  font-family: geo;
}

.side-mob-nav li {
  padding-left: 15px;
  list-style: none;
  padding-bottom: 15px;
  padding-top: 15px;
}

.side-mob-nav li:hover {
  color: #525252;
  cursor: pointer;
}

.nav-cross {
  position: fixed;
  top: 17px;
  right: 0;
  background-color: #f0f8ff;
  z-index: 12000px;
}

.nav-cross i {
  color: #fff;
}

.over-toggle {
  display: none;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15000;
}

@media (min-width: 1600px) {
  .intro-inner {
    margin-top: 12%;
  }
}

@media (max-width: 780px) {
  .horiz-line,
  .my-detail,
  .my-pic {
    margin-left: 30px;
  }

  .back-image {
    background: red right center no-repeat fixed;
    background-size: cover;
  }

  .header {
    display: none;
  }

  .logo {
    position: fixed;
    left: 10px;
    top: 5px;
    z-index: 1000;
  }

  .logo img {
    width: 40px;
  }

  .toggle-nav {
    display: inline-block;
    z-index: 12000;
  }

  .nav-line {
    display: inline;
    position: fixed;
    z-index: 12500;
    cursor: pointer;
  }

  .nav-line ul {
    padding-left: 0;
  }

  .my-pic {
    width: 110px;
    height: 125px;
  }

  .my-pic img {
    width: 140px;
  }

  .my-intro {
    font-size: 1.8em;
  }

  .my-detail {
    font-size: 1.4em;
    margin-right: 0;
  }

  .horiz-line {
    width: 400px;
  }

  .dia-5 {
    margin-left: 300px;
  }

  .dia-4 {
    opacity: 0.3;
    position: absolute;
    z-index: -1;
    display: block;
    border: 2px solid #aa1616;
    margin-top: -260px;
    animation-name: trans4;
    margin-left: 270px;
  }

  .dia-2 {
    margin-top: -110px;
  }

  .dia-1 {
    margin-top: -80px;
  }

  .intro-outer {
    margin-top: -50px;
  }

  .intro-inner {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .links {
    margin-left: -30px;
  }
}

@media (max-width: 480px) {
  .horiz-line,
  .my-detail,
  .my-pic {
    margin-left: 30px;
  }

  .back-image {
    background: red -150px center no-repeat fixed;
  }

  .my-intro {
    font-size: 1.5em;
    line-height: 28px;
  }

  .my-detail {
    line-height: 27px;
    font-size: 1.4em;
    margin-right: 0;
  }

  .horiz-line {
    width: 390px;
  }

  .intro-inner {
    margin-top: 200px;
  }

  .my-pic {
    width: 95px;
    height: 105px;
  }

  .my-pic img {
    width: 120px;
  }
}

@media (max-width: 320px) {
  .horiz-line,
  .my-detail,
  .my-pic {
    margin-left: 30px;
  }

  .back-image {
    background: red -150px center no-repeat fixed;
  }

  .my-intro {
    font-size: 1.6em;
    line-height: 30px;
  }

  .my-detail {
    line-height: 24px;
    font-size: 1.2em;
    margin-right: 0;
  }

  .horiz-line {
    width: 360px;
  }

  .my-pic {
    width: 95px;
    height: 105px;
  }

  .my-pic img {
    width: 120px;
  }

  .intro-inner {
    margin-left: -10px;
  }
}
