.project-outer {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  color: #000;
  margin-top: 100vh;
  background-color: #121212;
  transition: opacity 0.5s ease-in-out;
}

.indiv,
.pro-outer ul li {
  transition: all 0.2s ease-in-out;
}

.back-color {
  display: block;
  width: 100%;
  height: 100%;
  background: #000 right center no-repeat fixed;
}

.project-title {
  display: inline-block;
  color: #fff;
  font-family: geo;
  font-size: 1.8em;
  margin-top: 80px;
  margin-left: 30px;
}

.subtitle-pro {
  color: #505050;
  font-family: mons-lig;
  font-size: 1.1em;
  margin-left: 30px;
  margin-top: 4px;
}

.line-exp,
.line-pro {
  margin-left: 32px;
  display: block;
  width: 350px;
  height: 1px;
  background-color: #4a4a4a;
  margin-top: 7px;
  margin-bottom: 7px;
}

.pro-outer {
  margin-top: 40px;
  display: block;
  width: 82%;
  height: 240px;
  position: relative;
  margin-left: 40px;
  overflow-x: hidden;
  overflow-y: none;
}

.pro-outer ul li {
  cursor: pointer;
  overflow: hidden;
  right: 0;
  list-style: none;
  display: inline-block;
  min-width: 160px;
  min-height: 220px;
  background-color: green;
}

.pro-outer ul {
  overflow: hidden;
  padding-left: 0;
  overflow-y: none;
  margin-bottom: 0;
  height: 230px;
}

.fjs {
  height: 220px;
  position: relative;
}

.fjs .fjsCenter {
  text-align: center;
  color: #fff;
  position: absolute;
  left: 36px;
  top: 162px;
}

.fjs img {
  width: auto;
  height: 130px;
  position: absolute;
  top: 40px;
  left: 34px;
}

.pro-inner {
  position: relative;
  overflow-y: none;
  display: inline-block;
  width: 2020px;
  height: 230px;
  padding-right: 40px;
}

.but,
.but2,
.buttons,
.founch-div,
.zoom {
  position: absolute;
}

.founch-div-in,
.inner-2,
.inner-3,
.inner-4,
.inner-5,
.inner-6,
.inner-7,
.inner-8,
.inner-9
.inner-10 {
  overflow: hidden;
}

.inner-1 {
  background-image: url(../../images/founch.png);
}

.inner-2 {
  background-image: url(../../images/msg-back.jpg);
}

.inner-3 {
  background-image: url(../../images/gmas.png);
}

.inner-4 {
  background-image: url(../../images/pmc.png);
}

.inner-5 {
  background-image: url(../../images/portfolio.jpg);
}

.inner-6 {
  background-image: url(../../images/graphics.png);
}

.inner-7 {
  background-image: url(../../images/ym-back.jpg);
}

.inner-8 {
  background-image: url(../../images/z-back.jpg);
}

.inner-9 {
  background-image: url(../../images/tap-back.jpg);
}

.inner-10 {
  background-image: url(../../images/floatjs.png);
}

.indiv {
  display: block;
  width: 100%;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.92);
}

.in {
  box-shadow: 2px 2px 4px #0b0b0b;
  display: inline-block;
  background-color: #fff;
  margin-left: 38px;
}

.buttons {
  display: block;
  width: 85%;
  height: 220px;
  margin: 40px 0 0 40px;
}

.but,
.but2,
.zoom img {
  width: 30px;
}

.but,
.but2 {
  margin-top: 85px;
  z-index: 2;
  display: inline-block;
  padding: 15px 10px;
  background-color: rgba(59, 59, 59, 0.8);
  transition: all .2s ease;
  height: 60px;
  border-radius: 0 60px 60px 0;
}

.but i,
.but2 i {
  color: #fff;
  opacity: 0.7;
}

.but2:hover,
.but:hover {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.but2:hover i,
.but:hover i {
  color: #000;
}

.but {
  right: 0;
}

.but2 {
  left: 0;
  border-radius: 60px 0 0 60px;
}

.pro-outer ul li:hover .founch {
  background-color: rgba(5, 71, 92, 0.9);
}

.pro-outer ul li:hover .hitit {
  background-color: rgba(72, 72, 72, 0.9);
}

.pro-outer ul li:hover .gmas {
  background-color: rgba(13, 93, 73, 0.9);
}

.pro-outer ul li:hover .pmc {
  background-color: rgba(9, 63, 67, 0.9);
}

.pro-outer ul li:hover .portfolio {
  background-color: rgba(127, 32, 32, 0.9);
}

.pro-outer ul li:hover .design {
  background-color: rgba(53, 106, 47, 0.9);
}

.pro-outer ul li:hover .ym {
  background-color: rgba(72, 72, 72, 0.9);
}

.pro-outer ul li:hover .ki {
  background-color: rgba(36, 27, 70, 0.9);
}

.pro-outer ul li:hover .tap {
  background-color: rgba(4, 22, 61, 0.9);
}

.zoom {
  display: inline;
  margin-left: 65px;
  z-index: -1;
  bottom: 0;
  transition: all 0.2s ease-in-out;
}

.cut,
.cut i,
.founch-over a {
  transition: all 0.2s ease;
}

.pro-outer ul li:hover .zoom {
  z-index: 1;
  bottom: 70px;
  display: block;
}

.left-text span,
.right-text span {
  display: block;
  font-size: 20px;
}

.f-text,
.left-text,
.right-text {
  font-family: geo;
  font-size: 2em;
}

.left-text,
.right-text {
  text-align: center;
  color: #676767;
  margin-top: 20px;
}

.right-text {
  display: block;
  border-left: 1px solid #676767;
}

.founch-div {
  z-index: 20000;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  display: block;
  overflow-y: scroll;
  right: 0;
  left: 0;
}

.banner,
.founch-over {
  height: 300px;
  display: block;
}

.founch-div-in {
  overflow-y: scroll;
  display: block;
  width: 100%;
}

.banner {
  top: 0;
  overflow: hidden;
  padding: 0;
  background-image: url(../../images/cel_img.jpg);
  background-size: 600px;
  box-shadow: 1px 1px 4px #333;
}

.banner img {
  width: 100%;
}

.founch-over {
  width: 100%;
  background-color: rgba(5, 71, 92, 0.9);
  text-align: center;
  padding-top: 60px;
}

.founch-div::-webkit-scrollbar {
  width: 4px;
  padding-right: 2px;
}

.founch-div::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}

.founch-div::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #145c89;
}

.founch-over img {
  width: 100px;
}

.f-text {
  color: #fff;
  margin-top: 15px;
}

.f-det {
  font-family: mons-lig;
  color: #e3e3e3;
  margin-top: 0;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.cut {
  display: block;
  padding: 8px 12px;
  background-color: #fff;
  position: fixed;
  z-index: 25000;
  top: 0;
  right: 20px;
  box-shadow: 1px 1px 2px #5c5c5c;
  cursor: pointer;
}

.cut:hover {
  padding: 12px;
}

.cut i {
  color: #4d4d4d;
}

.cut:hover i {
  color: #222;
  transform: rotate(180deg);
}

.f-intro h3,
.index-outer h3 {
  font-family: mons-reg;
  color: #9d9d9d;
  margin-top: 30px;
}

.f-intro,
.index-outer {
  font-family: mons-lig;
  text-align: center;
}

.color-theme h3 {
  color: #9d9d9d;
}

.f-intro {
  padding: 0 40px;
  color: #4d4d4d;
}

.founch-over a {
  color: #ddd;
}

#land-ui img,
#mob-ui img {
  padding: 0;
  transition: all 0.4s ease;
}

.founch-over a:focus,
.founch-over a:hover {
  color: #fff;
  text-decoration: none;
}

.index-outer {
  display: block;
  width: 90%;
  height: 600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: #4d4d4d;
}

.color-theme {
  margin-top: 10px;
}

.color-theme img {
  margin-top: 10px;
  width: 95%;
}

.color-theme:hover img {
  box-shadow: 0 0 7px #9d9d9d;
}

#land-ui img,
#mob-ui img,
#player {
  margin-top: 20px;
  box-shadow: 0 0 4px #9d9d9d;
}

#player {
  width: 600px;
  height: 350px;
}

#land-ui img {
  width: 100%;
}

#land-ui img:hover,
#mob-ui img:hover {
  box-shadow: 0 0 20px #4d4d4d;
}

#mob-ui img {
  width: 70%;
}

#icons-ui {
  float: left;
  padding: 30px 40px;
}

#icons-ui img,
.last-container {
  padding-bottom: 20px;
}

#icons-ui img {
  width: 100%;
}

.text-center {
  text-align: center;
}

.last-line {
  margin-top: 10px;
  background-color: #9d9d9d;
  height: 2px;
  display: block;
}

.last-text {
  color: #9d9d9d;
}

@media (min-width: 1600px) {
  .buttons, .pro-outer {
    margin-top: 10%;
  }
}

@media (max-width: 720px) {
  .pro-outer ul li .founch {
    background-color: rgba(5, 71, 92, 0.9);
  }

  .pro-outer ul li .hitit {
    background-color: rgba(72, 72, 72, 0.9);
  }

  .pro-outer ul li .gmas {
    background-color: rgba(13, 93, 73, 0.9);
  }

  .pro-outer ul li .pmc {
    background-color: rgba(9, 63, 67, 0.9);
  }

  .pro-outer ul li .portfolio {
    background-color: rgba(127, 32, 32, 0.9);
  }

  .pro-outer ul li .design {
    background-color: rgba(53, 106, 47, 0.9);
  }

  .pro-outer ul li .zoom {
    z-index: 1;
    bottom: 70px;
    display: block;
  }

  .color-theme img {
    height: 90px;
  }

  #player {
    margin-top: 20px;
    width: 460px;
    height: 260px;
  }

  .vid-over {
    width: 460px !important;
    height: 260px;
  }

  .vid-over .yt-icon {
    width: 60px !important;
    top: 115px !important;
  }

  #mob-ui img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .project-title {
    font-size: 1.5em;
  }

  .left-text span,
  .right-text span {
    font-size: 17px;
  }

  .left-text,
  .right-text {
    text-align: left;
    font-size: 1.7em;
    padding-left: 40px;
  }

  .right-text {
    border-top: 1px solid #676767;
    border-top: 0;
  }

  .pro-title {
    font-size: 1.7em;
  }

  .banner {
    background-size: 400px;
    height: 200px;
  }

  .banner img {
    background-size: 100px;
  }

  .color-theme img {
    width: 100%;
    height: 100px;
  }

  #player,
  .vid-over {
    width: 100% !important;
    height: 225px;
  }
}
