.chart h4,
.skills-card {
	text-align: center;
	font-family: geo
}
.skills-outer {
	display: block;
	width: 100vw;
	height: 100vh;
	position: absolute;
	color: #000;
	margin-top: 318vh;
	background-color: #355088;
	z-index: 4;
	transition: opacity .5s ease-in-out
}
.subtitle-skills {
	color: #8b9dc3
}
.line-skills {
	width: 210px;
	background: #dfe3ee
}
.skills-card img {
	height: 60%;
	margin-top: 5px;
	margin-bottom: 5px
}
.skills-card {
	height: 100px;
	border-radius: 2px;
	position: relative;
	background-color: rgba(3, 22, 61, .9);
	box-shadow: 1px 1px 1px #213d74;
	transition: all .2s ease;
	margin-bottom: 10px;
	color: #dfe3ee;
	cursor: pointer;
	font-size: 1em;
	overflow: hidden
}
.code-chart,
.design-chart {
	transition: all .5s ease;
	box-shadow: 1px 1px 2px #051840
}
.skills-card:hover {
	background-color: rgba(3, 22, 61, .8)
}
.even {
	background-color: rgba(255, 255, 255, .8);
	color: #4d4d4d
}
.even:hover {
	background-color: rgba(255, 255, 255, .7);
	color: #4d4d4d
}
.skill-cover {
	display: block;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: -1;
	height: 100px
}
.code-chart,
.design-chart,
.skills-img {
	display: inline-block
}
.skill-redux{
	background: url(../../images/redux-skill.png) right center no-repeat;
	background-size: 160%;
}
.skill-node{
	background: url(../../images/node-js-skill.png) right center no-repeat;
	background-size: 160%;
}
.skill-react{
	background: url(../../images/react-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-css {
	background: url(../../images/css-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-js {
	background: url(../../images/js-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-jq {
	background: url(../../images/jquery-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-html {
	background: url(../../images/html-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-bs {
	background: url(../../images/bs-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-json {
	background: url(../../images/json-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-ang-js {
	background: url(../../images/ang-js-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-sass {
	background: url(../../images/sass-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-ai {
	background: url(../../images/ai-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-ae {
	background: url(../../images/ae-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-ps {
	background: url(../../images/ps-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-polymer {
	background: url(../../images/polymer-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-bal {
	background: url(../../images/bal-skill.png) right center no-repeat;
	background-size: 160%
}
.skill-c2 {
	background: url(../../images/c2-skill.png) right center no-repeat;
	background-size: 160%
}
.left-skills {
	margin-left: 20px
}
.skills-last {
	color: #7D98CF
}
.skills-img {
	width: 80px;
	height: 50px;
	border-right: 1px solid #8b9dc3;
	padding: 0 20px;
	margin-top: 11px;
	float: left
}
.code-chart {
	width: 75px;
	height: 150px;
	border-radius: 100px 0 0 100px;
	background: #7D98CF;
	margin-top: 90px
}
.design-chart {
	width: 90px;
	height: 180px;
	border-radius: 0 100px 100px 0;
	background: #0A2356;
	margin-left: 5px;
	margin-top: 75px
}
.chart h4 {
	margin-top: 70px;
	line-height: 16px;
	color: #0A2356;
	font-size: .9em
}
.chart .design-chart h4 {
	margin-top: 85px;
	color: #7D98CF
}
.skills-content {
	padding-top: 28px;
	margin-top: 28px;
	margin-left: 20px
}
.skills-content h4 {
	color: #f9f9f9;
	font-family: mons-lig;
	font-size: 1.1em
}
.skills-content h2 {
	color: #7D98CF;
	font-size: 1.1em;
	font-family: geo
}
div.skills-out {
	overflow: hidden;
	height: 215px;
	display: block;
	margin-top: 70px
}
.skills-down,
.skills-up {
	display: block;
	width: 60px;
	height: 30px;
	border-radius: 60px 60px 0 0;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	cursor: pointer;
	z-index: 2;
	transition: all .2s ease-in-out
}
.skills-in {
	height: 540px
}
.skills-up {
	margin-top: 30px;
	background: #fff;
	background: rgba(179, 193, 226, .7)
}
.skills-down i,
.skills-up i {
	position: absolute;
	margin-left: 21px;
	margin-top: 2px;
	color: #355088;
	transition: all .2s ease-in-out
}
.skills-down {
	border-radius: 0 0 60px 60px;
	bottom: -35px;
	background: rgba(179, 193, 226, .7)
}
.skills-down:hover,
.skills-up:hover {
	background: rgba(179, 193, 226, 1)
}
.skills-down:hover i,
.skills-up:hover i {
	color: #fff
}
@media(min-width:1600px){div.skills-out{margin-top:18%}.skills-up{margin-top:13%}.chart{margin-top:12%}.skills-content{margin-top:16%}}@media(max-width:990px){.code-chart{margin-top:50px}.design-chart{margin-top:33px}.skills-content{margin-top:0;font-size:.9em}div.skills-out{height:88px}.skill-cover,.skills-card{height:80px}.skills-card{font-size:.7em}.skills-card .special-effect {
	margin-bottom: 0
}
.skills-down,
.skills-up {
	width: 50px;
	height: 25px;
	border-radius: 50px 50px 0 0
}
.skills-down {
	bottom: -30px;
	border-radius: 0 0 50px 50px
}
.skills-down i,
.skills-up i {
	margin-left: 16px;
	margin-top: 0
}
.skills-last {
	margin-top: 30px
}
.align-space {
	margin-left: 20px
}
}
@media(max-width:652px){.skills-content{margin-top:-10px}}@media(max-width:480px){.skills-card .special-effect {
	margin-bottom: 5px
}
.skills-card {
	font-size: .6em
}
}